import CopyAllIcon from "@mui/icons-material/CopyAll";
import DescriptionIcon from "@mui/icons-material/Description";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type { ReceiptContentProps } from "../../types/index.types";
import {
	downloadEticket,
	energies,
	getCurrencyNumber,
	getDate,
} from "../../utils/receipt";
import SimpleDialog from "../Dialog";
import { LogoImage } from "./Receipt";
import ArrowForward from "@mui/icons-material/ArrowForward";

export const ReceiptFound = ({
	data,
	locale,
	handleSubmit,
	downloadPdf,
	isPdfLoading,
	isEmailLoading,
	refForm
}: ReceiptContentProps) => {
	const { t } = useTranslation();
	const [openEticket, setOpenEticket] = useState(false);
	if (!data) return null;
	return (
		<>
			<Card className="card-container">
				<div className="receipt-container">
					<div className="nav">
						<LogoImage operatorLogo={data.operatorLogo} />
						<h1>{t("receipt.title")}</h1>
					</div>
					<div className="header">
						<div className="field">
							<div>{t("receipt.date")}:</div>
							<div>
								{getDate(new Date(data.paymentDate), data.timeZone, locale)}
							</div>
						</div>
						<div className="field">
							<div>{t("receipt.station")}:</div>
							<div>
								{data.stationName} - {data.dispenserName}
							</div>
						</div>
						<div className="field">
							<div>{t("receipt.address")}:</div>
							<div>{data.stationAddress}</div>
						</div>
					</div>
					<div className="body">
						<div className="field">
							<div className="title">{t("receipt.product")}</div>
							<div>{energies[data.energy][data.productName]}</div>
						</div>
						<div className="field">
							<div className="title">{t("receipt.unit_price")}</div>
							<div>
								{getCurrencyNumber(data.unitPrice, data.currency, locale)} /{" "}
								{data.quantityUnit}
							</div>
						</div>
						<div className="field">
							<div className="title">{t("receipt.quantity")}</div>
							<div>
								{data.volume} {data.quantityUnit}
							</div>
						</div>
						<div className="field">
							<div className="title">{t("receipt.price_excl_tax")}</div>
							<div>
								{getCurrencyNumber(data.amountHT, data.currency, locale)}
							</div>
						</div>
						<div className="field">
							<div className="title">
								{t("receipt.tax")} ({data.taxe}%)
							</div>
							<div>
								{getCurrencyNumber(
									data.amount - data.amountHT,
									data.currency,
									locale,
								)}
							</div>
						</div>
						<div className="field">
							<div className="title">{t("receipt.total_price")}</div>
							<div className="total-price">
								{getCurrencyNumber(data.amount, data.currency, locale)}
							</div>
						</div>
					</div>
				</div>
			</Card>
			<Accordion className="accordion-panel">
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1-content"
					id="panel1-header"
					className="accordion-summary"
				>
					<div className="accordion-header">
						<DescriptionIcon />
						<span className="accordion-header-title">
							{t("receipt.payment_informations")}
						</span>
					</div>
				</AccordionSummary>
				<div className="accordion-separator" />
				<AccordionDetails>
					<div className="payment-infos">
						<div className="field">
							<div>{t("receipt.payment_method")}:</div>
							<div>{t(`receipt.${data.cardData.cardPaymentMethod}`)}</div>
						</div>
						<div className="field">
							<div>{t("receipt.transaction_type")}:</div>
							<div>{t(`receipt.${data.cardData.accountType}`)}</div>
						</div>
						<div className="field">
							<div>{t("receipt.card_type_id")}:</div>
							<div>{data.cardData.cardTypeId}</div>
						</div>
						<div className="field">
							<div>{t("receipt.card_id")}:</div>
							<div>
								{data.cardData.brandName}{" "}
								{data.cardData.cardNumberPrintable
									.replaceAll("X", "*")
									.replace(/(.{4})/g, "$1 ")
									.trim()}
							</div>
						</div>
						<div className="field">
							<div>{t("receipt.transaction_id")}:</div>
							<div>{data.transactionId}</div>
						</div>
						<div className="field">
							<div>{t("receipt.transfer_id")}:</div>
							<div>{data.cardData.paymentReference}</div>
						</div>
						<div className="field">
							<div>{t("receipt.terminal_id")}:</div>
							<div>{data.cardData.terminalId}</div>
						</div>
						<button
							type="button"
							className="text-button"
							onClick={() => setOpenEticket(true)}
						>
							<span>{t("receipt.technical_data")}</span>
							<ArrowForward className="main-color" />
						</button>
					</div>
				</AccordionDetails>
			</Accordion>
			<div className="buttons-container">
				<button
					type="button"
					className="main-button"
					disabled={isPdfLoading}
					onClick={() => (isPdfLoading ? null : downloadPdf())}
				>
					{isPdfLoading ? (
						<CircularProgress style={{ color: "white" }} size={16} />
					) : (
						t("receipt.download_pdf")
					)}
				</button>
			</div>
			<SimpleDialog
				open={openEticket}
				onClose={() => setOpenEticket(false)}
				title="E-Ticket"
				content={
					<div className="ticket-wrapper">
						<IconButton
							className="copy-all-wrapper"
							onClick={() => navigator.clipboard.writeText(data.receiptEticket)}
						>
							<CopyAllIcon />
						</IconButton>
						<pre className="content-e-ticket">{data.receiptEticket}</pre>
					</div>
				}
				primaryButtonTitle={t("receipt.download")}
				onHandlePrimaryButton={() => {
					const fileName =
						`eticket-${data.stationName}-${data.dispenserName}-${data.paymentDate}`
							.trim()
							.replace(/ /g, "_");
					downloadEticket(data.receiptEticket, fileName);
				}}
			/>
			<form ref={refForm} className="email-container" onSubmit={handleSubmit}>
				<input
					type="email"
					required
					placeholder={t("receipt.your_email_address")}
					name="email"
				/>
				<button type="submit">
					{isEmailLoading ? (
						<CircularProgress style={{ color: "white" }} size={14} />
					) : (
						t("receipt.send")
					)}
				</button>
				<small className="rgdp-info">
					{t("receipt.rgpd", { operator: "FillnDrive" })}
				</small>
			</form>
			<div className="footer-container">
				<h3>{data.operatorReceiptMessage ?? t("receipt.thanks_message")}</h3>
			</div>
		</>
	);
};
