import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReceiptData } from "../../hooks/useReceiptData";
import logo from "../../logo.png";
import { getReceiptPdf, saveEmail } from "../../services/Receipt";
import { getFormattedLanguage, getLocale } from "../../utils/receipt";
import "../style.scss";
import type { LogoImageProps } from "../../types/index.types";
import { ReceiptFound } from "./ReceiptFound";
import { ReceiptNotFound } from "./ReceiptNotFound";

export const LogoImage = ({ operatorLogo }: LogoImageProps) => (
	<img loading="lazy" src={operatorLogo || logo} className="logo" alt="logo" />
);

const Receipt = () => {
	const { receiptData, isLoading, token, boxName } = useReceiptData();
	const [isPdfLoading, setIsPdfLoading] = useState(false);
	const [isEmailLoading, setIsEmailLoading] = useState(false);
	const refForm = useRef<HTMLFormElement>(null);
	const { t, i18n } = useTranslation();
	const locale = getLocale(i18n.language);

	useEffect(() => {
		if (receiptData?.language) {
			const lang = getFormattedLanguage(receiptData.language);
			i18n.changeLanguage(lang);
		}
	}, [i18n, receiptData?.language]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const formData = new FormData(event.currentTarget);
		event.preventDefault();
		const email = formData.get("email");
		if (!email) return;
		setIsEmailLoading(true);
		const res = await saveEmail(token, boxName, email as string);
		setIsEmailLoading(false);
		refForm.current?.reset();
		console.log(res);
	};

	const downloadPdf = async () => {
		if (!token || !boxName) return;
		setIsPdfLoading(true);
		const data = await getReceiptPdf(token, boxName);
		setIsPdfLoading(false);
		if (!data?.pdfUrl) return;
		window.open(data.pdfUrl, "_blank");
	};

	if (isLoading) {
		return (
			<Container maxWidth="xs" className="receipt-main-container">
				<Card className="card-container">
					<div className="receipt-container">
						<div className="nav">
							<LogoImage />
							<h1>{t("receipt.title")}</h1>
						</div>
						<div className="header items-center">
							<CircularProgress />
						</div>
					</div>
				</Card>
			</Container>
		);
	}

	return (
		<Container maxWidth="xs" className="receipt-main-container">
			{receiptData?.error ? (
				<ReceiptNotFound
					data={receiptData}
					token={token}
					boxName={boxName}
					handleSubmit={handleSubmit}
					isEmailLoading={isEmailLoading}
					refForm={refForm}
				/>
			) : (
				<ReceiptFound
					data={receiptData}
					locale={locale}
					handleSubmit={handleSubmit}
					downloadPdf={downloadPdf}
					isPdfLoading={isPdfLoading}
					isEmailLoading={isEmailLoading}
					refForm={refForm}
				/>
			)}
		</Container>
	);
};

export default Receipt;
